import React from 'react';
import {CheckIcon} from "../icons/CheckIcon";

interface PriceCardProps {
    populair?: boolean;
    type: string;
    price: string;
    fullSuspensionPrice?: string;
    basedOn?: string;
    checks: React.ReactNode[];
}

export const PriceCard = ({populair = false, type, price, fullSuspensionPrice, basedOn, checks}: PriceCardProps) => (

    <div className="relative overflow-hidden p-4 bg-white rounded-lg border shadow-md sm:p-8 ">
        {populair &&
            <div className="absolute right-0 top-0 h-16 w-16">
                <div
                    className="absolute right-[-42px] top-[42px] w-[200px] transform rotate-45 bg-blue-700 text-center text-white font-semibold py-1">Populair
                </div>
            </div>}
        <h2 className="mb-4 text-3xl md:text-4xl font-bold text-gray-500">{type}</h2>
        <div className="flex items-baseline my-auto text-gray-700">
            <div>
                <span className="text-3xl font-semibold"> &euro; </span>
                <span className="text-3xl font-semibold">{price} </span>
                {fullSuspensionPrice &&
                    <>
                        <br/><span className="text-2xl font-semibold"> (full suspension </span>
                        <span className="text-2xl font-semibold"> &euro; </span>
                        <span className="text-2xl font-semibold"> {fullSuspensionPrice}) </span>
                    </>
                }
            </div>
        </div>
        {basedOn && <p className={"text-2xl text-gray-500 mt-3"}>{basedOn} +</p>}

        <ul role="list" className="my-3 space-y-5">
            {checks.map((check, index) =>
                <li className="flex space-x-3" key={index}>
                    <CheckIcon/>
                    <span className="text-base font-normal leading-tight text-gray-500 ">{check}</span>
                </li>)}
        </ul>
    </div>
);
