import React from "react";

export interface CheckIconProps {
    size?: number;
}

export const CheckIcon = ({
                              size = 5,
                              ...props
                          }: CheckIconProps) => {

    // const className = "flex-shrink-0 w-8 h-8 text-blue-600"
    const className = "flex-shrink-0 text-blue-600 align-baseline w-" +size+ " h-" +size
    return (
        <svg className={className} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"/>
        </svg>)
}



